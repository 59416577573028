footer {
    background: #12c2e9;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #f64f59, #c471ed, #12c2e9);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #f64f59, #c471ed, #12c2e9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    text-align: center;
    padding: 3rem 0;
    font-size: 0.9rem;
    margin-top: 7rem;
}

footer a:hover {
    color: var(--color-primary);
}

.footer__logo {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 2rem;
    background: var(--color-bg-variant);
    color: var(--color-text);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: inline-block;
    border: 1px solid transparent;
}

.permaLinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
}

.footer__socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
}

.footer__socials a {
    background: var(--color-bg-variant);
    color: var(--color-text);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
}

.footer__socials a:hover {
    background: transparent;
    color: var(--color-bg-variant);
}

.footer__socials-icon {
    font-size: 2.5rem;
}

.footer__copyright {
    margin-bottom: 4rem;
    color: var(--color-bg);
}

/* ==== MEDIA QUERY FOR MOBILE ==== */

@media screen and (max-width: 600px) {
   footer {
       margin-top: 13rem;
   }
   
    .permaLinks {
       flex-direction: column;
       gap: 1.5rem;
   }

   .footer__socials {
       margin-bottom: 2.6rem;
   }
}