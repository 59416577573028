@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;400;600&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #ffffff;
    --color-bg2: #7e7e7e;
    --color-bg-variant: #12c2e9;
    --color-primary: #f64f59;
    --color-primary-variant: rgba#ffffff;
    --color-white: rgb(8, 8, 8);
    --color-light: #f64f59;
    --color-text: #fff;

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Mukta', sans-serif;
    background: rgb(59, 10, 71);
    color: var(--color-white);
    line-height: 1.7;
}

/* ===== GENERAL STYLES ====== */

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
    color: var(--color-bg);
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 8rem;
    height: 100vh;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-text);
}

a {
    transition: var(--transition);
    color: #fff;
}

a:hover {
    color: var(--color-bg-primary);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-bg);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 2px solid #fff;
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-bg-variant);
    color: var(--color-bg);
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
    border: 2.4px solid var(--color-text);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* ==== MEDIA QUERY FOR TABLET ==== */

@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }
}

/* ==== MEDIA QUERY FOR MOBILE ==== */

@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

    section > h2 {
        margin-bottom: 2rem;
    }

    section {
        height: 135vh;
        margin-bottom: rem;
    } 


}