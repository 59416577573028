#contact h5, 
#contact h2 {
    color: var(--color-text);
}

.contact {
    margin-bottom: 1.4rem;
}

.container.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__card {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__card:hover {
    background: var(--color-primary);
    border-color: var(--color-primary-variant);
}

.contact__card a:hover {
    color: var(--color-bg-variant);
}

.contact__card-icon {
    color: #fff;
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
}

.contact__card a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 1rem;
}


/* === FORM === */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

::placeholder {
    color: #fff;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: var(--color-bg-variant);
    border: 4px solid var(--color-text);
    resize: none;
    color: var(--color-text);
    font-size: 1.2rem;
}

/* ==== MEDIA QUERY FOR TABLET ==== */

@media screen and (max-width: 1024px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

/* ==== MEDIA QUERY FOR MOBILE ==== */

@media screen and (max-width: 600px) {
   .container.contact__container {
       width: var(--container-width-sm);
   }
   
    .contact {
       margin-top: 40rem;
       margin-bottom: 3rem;
   }
}




