header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
    background: #12c2e9;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #f64f59, #c471ed, #12c2e9);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #f64f59, #c471ed, #12c2e9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* === CTA BUTTONS === */

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
}

/* === SOCIAL BUTTONS === */

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    bottom: 2.6rem;
    font-size: 2.5rem;
    background: transparent;
    color: var(--color-bg-variant);
}

.header__socials::after {
    content: '';
    width: 3px;
    height: 2rem;
    background-color: #fff;
}

/* === LOGO IMG  === */

.logo {
    width: 42rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 13rem);
}

/* === SCROLL DOWN  === */

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 400;
    font-size: 0.9rem;
    color: var(--color-bg);
}

/* ==== MEDIA QUERY FOR TABLET ==== */

@media screen and (max-width: 1024px) {
    .header {
        height: 68vh;
    }
}

/* ==== MEDIA QUERY FOR MOBILE ==== */

@media screen and (max-width: 600px) {
    .header {
        height: 100vh;
        
    }

    .header__socials,
    .scroll__down {
        display: none;
    }

    .logo {
        margin-top: 4rem;
    }

    .cta {
        cursor: pointer;
    }

}